<template>
    <div>
        <styled-button
            @click="showModal1">
            Open Modal 1
        </styled-button>
        <br><br>
        <styled-button
            @click="showModal2">
            Open Modal 2
        </styled-button>
        <br><br>
        <styled-button
            @click="showModal3">
            Open Modal 3
        </styled-button>
        <br><br>
        <styled-button
            @click="showAdStudio">
            OPEN AD STUDIO
        </styled-button>
        <running-not-allowed-modal v-model="runningNotAllowed" />
        <dynamic-not-allowed-modal v-model="dynamicNotAllowed" />
        <customized-not-allowed-modal v-model="customizedNotAllowed" />

        <ad-studio
            v-if="adStudio"
            @close="closeAdStudio" />
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import RunningNotAllowedModal from '@/components/ad-studio/modals/RunningNotAllowedModal';
import DynamicNotAllowedModal from '@/components/ad-studio/modals/DynamicNotAllowedModal';
import CustomizedNotAllowedModal from '@/components/ad-studio/modals/CustomizedNotAllowedModal';
import AdStudio from '@/components/ad-studio/AdStudio';

export default {
    components: {
        StyledButton,
        RunningNotAllowedModal,
        DynamicNotAllowedModal,
        CustomizedNotAllowedModal,
        AdStudio
    },
    data() {
        return {
            runningNotAllowed: false,
            dynamicNotAllowed: false,
            customizedNotAllowed: false,
            adStudio: false
        };
    },
    methods: {
        showModal1() {
            this.runningNotAllowed = true;
        },
        showModal2() {
            this.dynamicNotAllowed = true;
        },
        showModal3() {
            this.customizedNotAllowed = true;
        },
        showAdStudio() {
            this.adStudio = true;
        },
        closeAdStudio() {
            this.adStudio = false;
        }
    },
};
</script>

