<template>
    <styled-dialog
        :value="value"
        :width="800"
        @input="$emit('input', $event)">
        <h2 class="px-3 py-3 text-center">
            You cannot currently edit this ad type directly within BuyerBridge<br>
            because it has customized placements.
        </h2>
        <div class="layout justify-center mt-3">
            <styled-button>
                <icon
                    name="facebook-circle"
                    color="white"
                    size="20" />
                <span class="font-weight-bold ml-3">EDIT IN ADS MANAGER</span>
            </styled-button>
        </div>
        <div class="grey--text text-center mx-5 mt-5 mb-3">
            <em>We are working on supporting this feature in the future and appreciate your <br>
                patience in the meantime</em>
        </div>
        <template #actions>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('input', false)">
                Cancel
            </a>
        </template>
    </styled-dialog>
</template>

<script>
    import StyledDialog from '@/components/globals/StyledDialog';
    import StyledButton from '@/components/globals/StyledButton';
    import Icon from '@/components/globals/Icon';

    export default {
        components: {
            StyledDialog,
            StyledButton,
            Icon
        },
        props: {
            value: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {

            };
        },
    };
</script>

<style scoped lang="scss">
.layout {
    font-size: bold;
}
</style>
